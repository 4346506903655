import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination } from "../hooks/usePagination";

export default function PaginationControls({ currentPage, maxPage, lastPage, previousEnabled, nextEnabled, setPage, setPreviousPage, setNextPage }:Pagination) {

  const showRightEllipsis = !lastPage && lastPage !== 0;

  return (
    <nav>
      <ul className="pagination">
        <li className="page-item cursor-pointer">
          <div className={`page-link ${previousEnabled ? '' : 'disabled'}`} aria-label="Previous" onClick={setPreviousPage}>
            <span aria-hidden="true">&laquo;</span>
          </div>
        </li>
        {new Array(maxPage + 1).fill(0).map((_, idx) => (
          <li className={`page-item cursor-pointer ${idx === currentPage ? 'active' : ''}`} key={`page-${idx}`} onClick={() => setPage(idx)}>
            <div className="page-link">
              {idx + 1}
            </div>
          </li>
        ))}
        {showRightEllipsis && <li className="page-item cursor-pointer">
          <div className="page-link">
            <FontAwesomeIcon icon={faEllipsis} />
          </div>
        </li>}
        <li className="page-item cursor-pointer">
          <div className={`page-link ${nextEnabled ? '' : 'disabled'}`} aria-label="Previous" onClick={setNextPage}>
            <span aria-hidden="true" className='d-hidden'>&raquo;</span>
          </div>
        </li>
      </ul>
    </nav>
  );
}