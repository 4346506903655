// Hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Utils
import api from "../../utils/api";
// Components
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Badge, ListGroup } from "react-bootstrap";
import Text from "../../components/Text";
import PlayerRow from "./Components/PlayerRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserModal from "../../components/UserModal";
// Types
import { EightBallSkillLevel, GamePlayer, NineBallSkillLevel, Opponent, Player } from "@cjsantee/cjsantee-types/pool";
// Assets
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { POOL_HANDICAPS } from "../../utils/constants";

function PoolGameUsers() {
  const navigate = useNavigate();
  const [opponents, setOpponents] = useState<Opponent[]>([]);
  const [selectedOpponents, setSelectedOpponents] = useState<Opponent[]>([]);
  const [players, setPlayers] = useState<GamePlayer[]>([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [gameType, setGameType] = useState('9-Ball');

  const [page, setPage] = useState('confirmPlayers');

  const getOpponents = async () => {
    const {data, success} = await api.get('/pool/players');
    if(success) {
      setOpponents(data.players);
    }
  }

  useEffect(() => {
    getOpponents();
  }, []);

  useEffect(() => {
    if(gameType === '8-Ball' && selectedOpponents.length > 2) {
      const newOpponents = [selectedOpponents[0], selectedOpponents[1]];
      setSelectedOpponents(newOpponents);
    }
  }, [gameType, selectedOpponents]);

  const toggleSelected = (player: Opponent) => {
    const maxSelected = gameType === '8-Ball' ? 2 : 4;

    let newOpponents: Opponent[] = [...selectedOpponents];
    if(selectedOpponents.find(o => o.pool_player_id === player.pool_player_id)) {
      newOpponents = selectedOpponents.filter(o => o.pool_player_id !== player.pool_player_id);
    } else if(selectedOpponents.length >= maxSelected) {
      return;
    } else {
      newOpponents.push(player);
    }

    setSelectedOpponents(newOpponents);
  }

  const startGame = async () => {
    const gamePlayers: Player[] = players.filter(p => !!p.pool_player_id).map(p => ({
      pool_player_id: p.pool_player_id || '',
      handicap: p.score,
    }));
    const {data, success} = await api.post('/pool/games', {players: gamePlayers, game_type: gameType});

    if(success) {
      const {pool_game_id} = data;
      navigate(`/pool/${pool_game_id}`);
    }
  }

  const updatePlayerHandicap = async (pool_player_id: string | null, action: string) => {
    const newPlayers: GamePlayer[] = players.map(player => {
      if(player.pool_player_id !== pool_player_id) {
        return player;
      }
      const {score: currentHandicap} = player;
      let newHandicap = currentHandicap;
      if(currentHandicap === 0 && action === 'subtract') {
        newHandicap = currentHandicap;
      } else if(currentHandicap === 100 && action === 'add') {
        newHandicap = currentHandicap;
      } else {
        newHandicap = currentHandicap + (action === 'add' ? 1 : -1);
      }
      return {
        ...player,
        score: newHandicap,
      };
    });
    setPlayers(newPlayers);
  }

  const cancel = () => {
    if(page === 'confirmPlayers') {
      navigate('/pool');
    } else {
      setPage('confirmPlayers');
    }
  }

  const confirm = () => {
    if(!selectedOpponents.length) {
      return;
    }
    if(page === 'confirmPlayers') {
      if(gameType === '9-Ball') {
        const newPlayers = selectedOpponents.map((opponent) => {
          const {pool_player_id, display_name, user_id, skill_levels} = opponent;
          let skillLevel: NineBallSkillLevel = 3;
          if(skill_levels?.["9-Ball"]) {
            skillLevel = skill_levels["9-Ball"];
          }

          const handicap = POOL_HANDICAPS['9-Ball'][skillLevel];

          return {
            pool_player_id,
            user_id, 
            display_name,
            score: handicap,
            remaining: handicap,
            handicap,
            fouls: 0,
            winner: false, 
            racks: [],
          }
        });
        setPlayers(newPlayers);
      } else if(gameType === '8-Ball') {
        const [player, opponent] = selectedOpponents;
        const playerSkillLevel: EightBallSkillLevel = player?.skill_levels?.["8-Ball"] || 3;
        const opponentSkillLevel: EightBallSkillLevel = opponent?.skill_levels?.["8-Ball"] || 3;

        const playerHandicap = POOL_HANDICAPS['8-Ball'][playerSkillLevel][opponentSkillLevel];
        const opponentHandicap = POOL_HANDICAPS['8-Ball'][opponentSkillLevel][playerSkillLevel];

        const newPlayers = [
          {
            pool_player_id: player.pool_player_id,
            user_id: player.user_id,
            display_name: player.display_name,
            score: playerHandicap,
            remaining: playerHandicap,
            fouls: 0,
            handicap: playerHandicap,
            winner: false, 
            racks: [],
          },
          {
            pool_player_id: opponent.pool_player_id,
            user_id: opponent.user_id,
            display_name: opponent.display_name,
            score: opponentHandicap,
            remaining: opponentHandicap,
            fouls: 0,
            handicap: opponentHandicap,
            winner: false, 
            racks: [],
          }
        ];
        setPlayers(newPlayers);
      }
      setPage('setHandicaps');
    } else {
      startGame();
    }
  }

  return (
    <>
      <div className="container h-100 d-flex flex-column">
        <ButtonGroup className="mb-3">
          <Button 
            onClick={() => setGameType('8-Ball')}
            variant={gameType !== '8-Ball' ? 'outline-primary' : 'primary'}>8-Ball</Button>
          <Button 
            onClick={() => setGameType('9-Ball')}
            variant={gameType !== '9-Ball' ? 'outline-primary' : 'primary'}>9-Ball</Button>
        </ButtonGroup>
        <div className="d-flex align-items-center justify-content-between">
          <Text size={5}>{page === 'confirmPlayers' ? 'Select Players' : 'Update Handicaps'}</Text>
          {page === 'confirmPlayers' && (
            <Button onClick={() => setShowUserModal(true)}>
              <FontAwesomeIcon icon={faUserPlus} />
            </Button>
          )}
        </div>
        {page === 'confirmPlayers' 
          ? (
            <ListGroup className="mt-2 mb-3">
              {opponents.map((opponent) => {
                const selected_idx = selectedOpponents.findIndex(o => o.pool_player_id === opponent.pool_player_id);
                return (
                  <ListGroup.Item 
                    className="d-flex justify-content-between align-items-center cursor-pointer py-3"
                    key={`opponent-${opponent.pool_player_id}`} 
                    active={selected_idx !== -1}
                    onClick={() => toggleSelected(opponent)}
                  >
                    <Text size={4}>
                      {opponent.display_name}
                    </Text>

                    {(selected_idx !== -1) && 
                      <Badge bg="secondary" pill>
                        {selected_idx + 1}
                      </Badge>
                    }
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          )
          : (
            <div>
              {players.map((player) =>  (
                <PlayerRow key={`player-${player.pool_player_id}`} player={player} updatePlayerScore={updatePlayerHandicap} />
              ))}
            </div>
          )}
        <div className="row">
          <Button className="col me-1" onClick={cancel} variant="light">Cancel</Button>
          <Button className="col ms-1" onClick={confirm}>{page === 'confirmPlayers' ? 'Confirm Players' : 'Start Game'}</Button>
        </div>
      </div>
      <UserModal 
        show={showUserModal} 
        onHide={() => setShowUserModal(false)} 
        user={null}
        code="pool"
        onSuccess={getOpponents}
      />
    </>
  );
}

export default PoolGameUsers;
