import { useNavigate, useParams } from "react-router-dom";
import LoadingContainer from "../../../components/LoadingContainer";
import Text from "../../../components/Text";
import { Button } from "react-bootstrap";
import { CoffeeBagDB } from "../../../@types/coffee";

interface PublicBagProps {
  coffee_bag: CoffeeBagDB|null
}
function PublicBag({coffee_bag}:PublicBagProps) {
  const navigate = useNavigate();

  if(!coffee_bag) {
    return (
      <div className="container h-75 d-flex flex-column justify-content-center align-items-center">
        <Text size={3} className="text-muted mb-3">Coffee Bag Not Found.</Text>
        <Button onClick={() => navigate('/coffee')}>Return to Search</Button>
      </div>
    )
  }

  return (
    <div className="container h-100 d-flex flex-column">
      <Text size={4}>{coffee_bag.bag_code}</Text>
      <Text size={4}>{coffee_bag.bean_name}</Text>
    </div>
  )
}

function PublicBagWrapper() {
  const {bag_code} = useParams();

  return (
    <LoadingContainer url={`/public/coffee/bags/${bag_code}`}>
      <PublicBag coffee_bag={null}/>
    </LoadingContainer>
  )
}

export default PublicBagWrapper;