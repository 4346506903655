import { useEffect, useState } from "react";

interface PaginationProps {
  maxPageSize: number;
}
export interface Pagination {
  currentPage: number;
  setPage: (page: number) => void;
  maxPage: number;
  maxPageSize: number;
  lastPage: number | null;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  previousEnabled: boolean;
  setPreviousPage: () => void;
  nextEnabled: boolean;
  setNextPage: () => void;
}
const usePagination = ({ maxPageSize }: PaginationProps): Pagination => {
  // The current page
  const [currentPage, setCurrentPage] = useState(0);
  // The max page visited
  const [maxPage, setMaxPage] = useState(0);
  // The size of the current page
  const [pageSize, setPageSize] = useState(0);
  // The last page (if known)
  const [lastPage, setLastPage] = useState<number|null>(null);

  useEffect(() => {
    setMaxPage(Math.max(maxPage, currentPage));
  }, [maxPage, currentPage]);
  
  useEffect(() => {
    if (pageSize < maxPageSize && currentPage !== 0) {
      if(pageSize === 0) {
        setLastPage(currentPage - 1);
        setMaxPage(currentPage - 1);
        setCurrentPage(currentPage - 1);
      } else {
        setLastPage(currentPage);
      }
    }
  }, [maxPageSize, pageSize, currentPage]);

  const setPage = (page: number) => {
    setCurrentPage(page);
  }

  const setPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  }

  const setNextPage = () => {
    setCurrentPage(currentPage + 1);
  }

  const previousEnabled = currentPage > 0;

  const nextEnabled = lastPage === null || currentPage < lastPage;

  return {
    currentPage,
    setPage,
    maxPage,
    maxPageSize,
    lastPage,
    pageSize,
    setPageSize,
    previousEnabled,
    setPreviousPage,
    nextEnabled,
    setNextPage,
  };
};

export default usePagination;
