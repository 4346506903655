import { Routes, Route, Outlet } from "react-router-dom";
// Components
import { Spinner } from "react-bootstrap";
// Custom Components
import Header from './components/Header';
import AuthRoute from "./components/AuthRoute";
// Pages
import Home from "./pages/home";
import NotFound from './pages/not-found';
// Admin
import Admin from "./pages/admin";
import Logs from "./pages/admin/logs/Logs";
// Wordle
import Wordle from "./pages/wordle";
// Message
import Message from "./pages/message";
// Auth
import SignIn from "./pages/sign-in";
import SignUp from "./pages/sign-up";
// Chess
import Chess from "./pages/chess";
// Pool
import Pool from "./pages/pool";
import PoolGame from "./pages/pool/PoolGame";
import PoolGameUsers from "./pages/pool/PoolGameUsers";
import Profile from "./pages/profile";
import Compare from "./pages/pool/compare";
// Coffee
import Coffee from "./pages/coffee";
import CoffeeRoast from "./pages/coffee/roast";
import CoffeeRoasts from "./pages/coffee/roasts/CoffeeRoasts";
import CoffeeBeans from "./pages/coffee/components/CoffeeBeans";
import CoffeeBags from "./pages/coffee/bags/CoffeeBags";
import CoffeeBag from "./pages/coffee/bag/CoffeeBag";
import PublicBag from "./pages/coffee/bag/PublicBag";
// Hooks
import { useEffect } from "react";
import { useAuth } from "./hooks/useAuth";

function App() {
  return (
    <Routes>
      <Route element={<Layout />} >
        <Route path='/signin' element={<SignIn />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/' element={<Home />} />
        <Route path='/chess' element={<Chess />} />
        <Route path='/coffee' element={<Coffee />} />
        <Route path='/coffee/bags/code/:bag_code' element={<PublicBag />} />
        <Route element={<AuthRoute permission='admin' />}>
          <Route path='/admin' element={<Admin />} />
          <Route path='/admin/logs' element={<Logs />} />
        </Route>
        <Route element={<AuthRoute permission='admin' />}>
          <Route path='/wordle' element={<Wordle />} />
        </Route>
        <Route element={<AuthRoute permission='admin' />}>
          <Route path='/message' element={<Message />} />
        </Route>
        <Route element={<AuthRoute permission='coffee' />}>
          <Route path='/coffee/bags' element={<CoffeeBags />} />
          <Route path='/coffee/bags/:coffee_bag_id' element={<CoffeeBag />} />
          <Route path='/coffee/roasts' element={<CoffeeRoasts />} />
          <Route path='/coffee/roasts/new' element={<CoffeeBeans />} />
          <Route path='/coffee/roasts/:coffee_roast_id' element={<CoffeeRoast />} />
        </Route>
        <Route element={<AuthRoute permission='pool' />}>
          <Route path='/pool' element={<Pool />} />
          <Route path='/pool/compare/:user_id' element={<Compare />}/>
          <Route path='/pool/new' element={<PoolGameUsers />} />
          <Route path='/pool/:pool_game_id' element={<PoolGame />}/>
        </Route>
        <Route path='/users/:username' element={<Profile />} />
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  );
}

/** Add site header to all routes */
function Layout() {
  const {user, persist, loading, refresh} = useAuth();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      if(typeof refresh === 'function') {
        await refresh();
      }
      
    }
    if(!user && persist) {
      verifyRefreshToken();
    } 
  }, [user, persist, refresh]);

  if(loading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 vh-100">
        <Spinner animation="border" style={{ width: "3rem", height: "3rem" }} role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className='d-flex vh-100 flex-column'>
      <Header />
      <Outlet />
    </div>
  )
}

export default App;