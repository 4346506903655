// Hooks
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../../hooks/useSocket';
// Components
import Button from 'react-bootstrap/Button';
import GameRow from './Components/GameRow';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import SkillLevelModal from './Components/SkillLevelModal';
// Utils
import api from '../../utils/api';
// Types
import { Game, PlayerStats } from '@cjsantee/cjsantee-types/pool';
import { useAuth } from '../../hooks/useAuth';

const MatchEfficiencyTooltip = (
  <Tooltip id="tooltip">
    <strong>Match Efficiency (ME%)</strong>
    is a player’s winning percentage of their total matches.
  </Tooltip>
);

function Pool() {
  const socket = useSocket();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showSkillModal, setShowSkillModal] = useState(false);
  const [games, setGames] = useState<Game[]>([]);
  const [stats, setStats] = useState<PlayerStats>({
    games_played: 0,
    games_won: 0,
    win_percentage: 0,
    last_played: '',
    skill_levels: {
      '8-Ball': 3,
      '9-Ball': 3,
    },
    created: '',
    total_friends: 0,
  });

  const todayStr = new Date().toLocaleString('en-us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const getGames = useCallback(async () => {
    if (!user) {
      return;
    }
    const { data: gameData, success: gameSuccess } = await api.get('/pool/games');
    if (gameSuccess) {
      const { games } = gameData;
      setGames(games);
    }
    const { data: statsData, success: statsSuccess } = await api.get(`/pool/users/${user.user_id}`);
    if (statsSuccess) {
      setStats(statsData);
    }
  }, [user]);

  useEffect(() => {
    if (typeof socket.on === 'function') {
      socket.on('pool_games:new', () => {
        getGames();
      });
    }
    getGames();

    return () => {
      if (typeof socket.off === 'function') {
        socket.off('pool_games:new');
      }
    };
  }, [socket, getGames]);

  const onDelete = (pool_game_id: string) => {
    // Optimistic Update: Remove game
    setGames(games.filter((g) => g.pool_game_id !== pool_game_id));
    // Re-query for updated stats
    getGames();
  };

  return (
    <>
      <div className="container h-100 d-flex flex-column">
        <div className="bg-body sticky-top">
          <hr className="mt-0 mb-2" />
          <div className="row justify-content-between">
            <div className="col-4 d-flex flex-column justify-content-start align-items-start">
              <p className="m-0 text-muted text-start">Total</p>
              <p className="m-0 fw-bold text-start">{stats.games_played} Matches</p>
            </div>
            <OverlayTrigger placement="bottom" overlay={MatchEfficiencyTooltip}>
              <div className="col-4 d-flex flex-column justify-content-start align-items-center">
                <p className="m-0 text-muted text-center">ME %</p>
                <p className="m-0 fw-bold text-center">
                  {stats.win_percentage}
                </p>
              </div>
            </OverlayTrigger>
            <div
              onClick={() => setShowSkillModal(true)}
              className="col-4 d-flex flex-column justify-content-start align-items-end cursor-pointer">
              <p className="m-0 text-muted text-end text-decoration-underline">Skill Level</p>
              <p className="m-0 fw-bold text-end">
                {stats.skill_levels['8-Ball']}{' '}
                <span className="text-muted">
                  <small>(8)</small>
                </span>{' '}
                / {stats.skill_levels['9-Ball']}{' '}
                <span className="text-muted">
                  <small>(9)</small>
                </span>
              </p>
            </div>
          </div>
          <Button className="mt-2 w-100" onClick={() => navigate('/pool/new')}>
            New Game
          </Button>
          <hr className="my-2" />
        </div>
        <div className="mb-5">
          {games.map((game) => (
            <GameRow
              key={`${game.pool_game_id}`}
              game={game}
              todayStr={todayStr}
              deleteCb={() => onDelete(game.pool_game_id)}
              refreshGames={getGames}
            />
          ))}
        </div>
      </div>

      <SkillLevelModal
        skill_levels={stats.skill_levels}
        show={showSkillModal}
        onUpdate={getGames}
        onHide={() => setShowSkillModal(false)}
      />
    </>
  );
}

export default Pool;
