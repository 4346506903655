import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import api from "../../utils/api";
import UserModal from "../../components/UserModal";
import { useConfirm } from "../../hooks/useConfirm";
import UserBody from "./components/UserBody";
import { User } from "../../@types/auth";
import { useNavigate } from "react-router-dom";

function Admin() {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User|null>(null);
  const [showUserModal, setShowUserModal] = useState(false);

  const confirm = useConfirm();
  const navigate = useNavigate();

  const getUsers = async () => {
    const {data, success} = await api.get('/users');
    if(success) {
      setUsers(data.users);
    }
  }

  const selectUser = (user: User|null) => {
    setSelectedUser(user);
    setShowUserModal(true);
  }
  
  const deleteUser = async (username: string) => {
    const {success} = await api.delete(`/users/${username}`);
    if(success) {
      getUsers();
    }
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Container>
        <div className="d-flex justify-content-end">
          <Button onClick={() => navigate('/admin/logs')}>Logs</Button>
        </div>
        <h2>Users</h2>
        <Accordion className="mb-2">
          {users.map(user => (
            <Accordion.Item key={user.user_id} eventKey={user.user_id}>
              <Accordion.Header>
                <span className="my-1">{user.username}</span>
              </Accordion.Header>
              <Accordion.Body className="px-2"> 
                <UserBody user={user} 
                  updateUsers={getUsers}
                  onEdit={() => selectUser(user)} 
                  onDelete={() => {
                    if(confirm) confirm(
                      () => deleteUser(user.username), // onConfirm
                      () => {},                       // onCancel
                      'Are you sure?',                // header
                      'Do you really want to delete this user? This process cannot be undone.' // body
                    );
                  }}/>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        <Button onClick={() => selectUser(null)}>Add User</Button>
      </Container>
      <UserModal
        user={selectedUser}
        show={showUserModal}
        onHide={() => {setShowUserModal(false); getUsers();}} 
      />
    </>
  )
}

export default Admin;
