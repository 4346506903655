// Components
import Text from "../../components/Text";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScoreBoard from "./Components/ScoreBoard";
import PlayerRow from "./Components/PlayerRow";
// Assets
import { faChevronLeft, faGear, faUsers } from "@fortawesome/free-solid-svg-icons";
// Hooks
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSocket } from "../../hooks/useSocket";
import { useAlert } from "../../hooks/useAlert";
// Types
import { GamePlayerDetail, GameType } from "@cjsantee/cjsantee-types/pool";
// Utils
import api from "../../utils/api";

function PoolGame() {
  const { pool_game_id } = useParams();
  const navigate = useNavigate();
  const socket = useSocket();
  const alertManager = useAlert();
  const [title] = useState(new Date().toLocaleDateString());
  const [editing] = useState(pool_game_id === 'new');

  const [gameType, setGameType] = useState<GameType>('9-Ball');
  const [players, setPlayers] = useState<GamePlayerDetail[]>([]);
  const [activePlayer, setActivePlayer] = useState<null|string>(null);

  useEffect(() => {
    const getGameData = async () => {
      const {data} = await api.get<{game: {game_type: GameType, players: GamePlayerDetail[]}}>(`/pool/games/${pool_game_id}`);
      if(data) {
        const { game } = data;
        setPlayers(game.players);
        setGameType(game.game_type);
      }
    };
    if(typeof socket.on === 'function') {
      socket.on('pool_game:update', () => {
        getGameData();
      });
    }
    if(typeof socket.emit === 'function') {
      socket.emit('pool_game:id', pool_game_id);
    }
    getGameData();

    return () => {
      if(typeof socket.off === 'function') {
        socket.off('pool_game:update');
      }
    }
  }, [pool_game_id, socket]);

  /**
   * @description Toggle the active player
   */
  const togglePlayer = (pool_player_id: string) => {
    if(activePlayer === pool_player_id) {
      setActivePlayer(null);
      return;
    }
    setActivePlayer(pool_player_id);
  }

  const updatePlayerScore = async (pool_player_id: string | null, action: string) => {    
    const oldPlayers = [...players];
    // If there already exists a winner, don't update the scores unless you're subtracting from their score
    const winner = players.find(p => p.winner);
    const currentPlayer = players.find(p => p.pool_player_id === pool_player_id);
    // Favoring reability over code-golf here :)
    if(winner) {
      if(currentPlayer?.pool_player_id === winner?.pool_player_id && action === 'subtract') {
        // Don't return 
      } else {
        return;
      }
    }

    // Optimistic Update
    const newPlayers = players.map((player) => {
      let score = player.score;
      if(player.pool_player_id === pool_player_id) {
        if(action === 'add' && score < player.handicap) {
          score += 1;
        } else if(action === 'subtract' && score > 0) {
          score -= 1;
        }
      }

      return {
        ...player,
        score,
      };
    });
    setPlayers(newPlayers);

    const { data, success, error } = await api.post(
      `/pool/games/${pool_game_id}/scores`,
      { pool_player_id, action },
    );
    if(success) {
      const { game } = data;
      setPlayers(game.players);
      if(typeof socket.emit === 'function') {
        socket.emit('pool_game:update', pool_game_id);
      }
    } else if(error) {
      setPlayers(oldPlayers);
      if(typeof alertManager.addAlert === 'function') {
        alertManager.addAlert({type: 'danger', message: error.message, timeout: 3000});
      }
    }
  }

  return (
    <div className="container h-100 d-flex flex-column">
      
      <div className="d-flex justify-content-between align-items-center">
        <Button onClick={() => navigate('/pool')}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <Text size={5}>{title}</Text>
        <Button className="opacity-0">
          <FontAwesomeIcon icon={editing ? faUsers : faGear} />
        </Button>
      </div>

      {players.map((player) => (
        <PlayerRow key={`player-${player.pool_player_id}`} 
          player={player} 
          editing={editing} 
          active={!!player.pool_player_id && player.pool_player_id === activePlayer} 
          togglePlayer={togglePlayer}
          updatePlayerScore={updatePlayerScore}
        />
      ))}
        
      <ScoreBoard players={players} game_type={gameType}/>
    </div>
  )
}

export default PoolGame;
