import { useCallback, useEffect, useState } from "react"
import api from "../../../utils/api";
import { StoredLog } from "@cjsantee/cjsantee-types";
import PaginationControls from "../../../components/PaginationControls";
import usePagination from "../../../hooks/usePagination";

export default function Logs() {
  const [logs, setLogs] = useState<StoredLog[]>([]);
  
  const maxPageSize = 10;

  const pagination = usePagination({ maxPageSize });
  const { currentPage, setPageSize } = pagination;

  const getLogs = useCallback(async () => {
    const offset = currentPage * maxPageSize; 
    const { data, success } = await api.get(`/logs?limit=${maxPageSize}&offset=${offset}`);
    if(success) {
      const { logs: server_logs } = data;
      setLogs(server_logs);
    }
  }, [currentPage]);

  useEffect(() => {
    getLogs();
  }, [getLogs]);

  useEffect(() => {
    setPageSize(logs.length);
  }, [logs, setPageSize]);

  return (
    <div className="container">
      <div className="d-flex justify-content-center">
        <PaginationControls
          {...pagination}
        />
      </div>
      <ul className="list-group mb-2">
        {logs.map((log) => (
          <LogRow key={log.stored_log_id} log={log} />
        ))}
      </ul>
    </div>
  );
}

interface LogRowProps {
  log: StoredLog;
}
function LogRow({log}:LogRowProps) {
  const createdStr = new Date(log.created).toLocaleString('en-us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    minute: '2-digit',
    hour: '2-digit',
  });

  const textColor = () => {
    switch(log.log_level.toLowerCase()) {
      case 'debug':
        return 'secondary';
      case 'info':
        return 'info';
      case 'warn':
        return 'warning';
      case 'error':
        return 'danger';
    }
  }

  return (
    <li className="bg-card list-group-item d-flex flex-column overflow-hidden">
      <span className={`d-flex w-100 justify-content-center fw-bold text-${textColor()}`}>{createdStr}</span>
      {log.username && <span className="fw-bold">{log.username}</span>}
      <p className="m-0 small">{log.message}</p>
    </li>
  );
}